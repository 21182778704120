import React from "react";

const NoMatchPage: React.FC = () => {
    return (
        <div className="App">
            <header className="App-header">
                <p>404 NoMatch</p>
            </header>
        </div>
    );
};

export default NoMatchPage;
